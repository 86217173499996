import ChatService from '@/services/chat.service';
import UserService from '@/services/user.service';

export const state = {
  chats: [],
  onlineUsers: [],
  contacts: [],
  chatMessages: {},
  selectedChat: null,
  searchQuery: '', // Add searchQuery state
};

export const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
  SET_CHATS(state, chats) {
    state.chats = chats;
  },
  UPDATE_CHAT(state, { chat }) {
    const chatIndex = state.chats.findIndex(c => c.chat_id === chat.chat_id);
    if(chatIndex === -1) {
      state.chats = [ chat, ...state.chats ]
    } else {
      state.chats[chatIndex] = { ...state.chats[chatIndex], ...chat }
    }
  },
  UPDATE_EMPTY_CHAT(state, { chat }) {
    const chatIndex = state.chats.findIndex(c => c.chat_id === -1);
    if(chatIndex === -1) {
      state.chats = [ chat, ...state.chats ]
    } else {
      state.chats[chatIndex] = chat
    }
  },
  SET_CHAT_MESSAGES(state, { chatId, messages }) {
    state.chatMessages[chatId] = messages;
  },
  SET_MESSAGES(state, { chatId, messages }) {
    state.chatMessages[chatId] = messages;
  },
  SET_SELECTED_CHAT(state, chatId) {
    state.selectedChat = chatId;
  },
  ADD_MESSAGE(state, { chatId, message, message_out }) {
    if (!state.chatMessages[chatId]) {
      state.chatMessages[chatId] = [];
    }
    state.chatMessages[chatId] = [ ...state.chatMessages[chatId], message ]
    // state.chatMessages[chatId].push(message);

    const chatIndex = state.chats.findIndex(chat => chat.chat_id === chatId);
    if (chatIndex !== -1) {
      state.chats[chatIndex].lastMessage = message;
      if(!message_out && state.chats[chatIndex].is_participant) {
        state.chats[chatIndex].unread_count += 1;
      }

      // Move chat to the top of the list
      const updatedChat = state.chats.splice(chatIndex, 1)[0];
      state.chats = [updatedChat, ...state.chats];
    }
  },
  UPDATE_MESSAGE(state, { chatId, message }) {
    const messages = state.chatMessages[chatId];
    if (messages) {
      const index = messages.findIndex(m => m.id === message.id);
      if (index !== -1) {
        messages[index] = { ...messages[index], ...message };
      }
    }
  },
  DELETE_MESSAGE(state, { chatId, messageId }) {
    const messages = state.chatMessages[chatId];
    if (messages) {
      state.chatMessages[chatId] = messages.filter(m => m.id !== messageId);
    }
  },
  SET_SEARCH_QUERY(state, query) { // Add mutation for searchQuery
    state.searchQuery = query;
  },
};

export const actions = {
  async getList({ commit }) {
    try {
      const list = await ChatService.getList();
      commit('SET_CHATS', list);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  },
  async fetchChats({ commit }) {
    try {
      const response = await ChatService.getChats();
      commit('SET_CHATS', response.chats);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
    // ChatService.fetchChats();
  },
  async fetchChatMessages({ commit }, chatId) {
    try {
      const response = await ChatService.getChatMessages(chatId);
      commit('SET_CHAT_MESSAGES', { chatId, messages: response.data.messages });
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  },
  // eslint-disable-next-line
  async setMute({ commit }, muteStatus) {
    try {
      const chat_id = state.selectedChat;
      const response = await ChatService.setMute(chat_id, muteStatus);
      commit('UPDATE_CHAT', { chat: response.chat });
    } catch (error) {
      console.error('Error updating chat:', error);
    }
  },
  async createChat({ dispatch }, data) {
    try {
       await ChatService.createChat(data);
      dispatch('getList');
      // commit('ADD_CHAT', { chat: response.data?.chat });
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  },
  async fetchMessages({ commit }, chatId) {
    try {
      const response = await ChatService.fetchMessages(chatId);
      if(response.success) {
        commit('SET_CHAT_MESSAGES', { chatId, messages: response.messages });
        commit('UPDATE_CHAT', { chat: response.chat });
      }

    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    //ChatService.fetchMessages(chatId);
  },
  async getChatMembers({ commit }, chatId) {
    try {
      const response = await ChatService.getChatMembers(chatId);
      commit('UPDATE_CHAT', { chat: response.chat });
    } catch (error) {
      console.error('Error fetching chat members:', error);
    }
  },
  async delChatMember({ commit }, userId) {
    try {
      const response = await ChatService.delChatMember(state.selectedChat, userId);
      commit('UPDATE_CHAT', { chat: response.chat });
    } catch (error) {
      console.error('Error deleting chat member:', error);
    }
  },
  async addChatMembers({ commit }, users) {
    try {
      const response = await ChatService.addChatMembers(state.selectedChat, users);
      commit('UPDATE_CHAT', { chat: response.chat });
    } catch (error) {
      console.error('Error adding chat members:', error);
    }
  },
  async getContacts({ commit }) {
    try {
      const response = await ChatService.getContacts();
      commit('SET_CONTACTS', response );
    } catch (error) {
      console.error('Error fetching chat members:', error);
    }
  },
  selectChat({ commit, dispatch }, chatId) {
    commit('SET_SELECTED_CHAT', chatId);
    dispatch('fetchMessages', chatId);
    setTimeout(()=>{
      dispatch('getChatMembers', chatId);
    },200)
  },
  async sendMessage({ commit }, { chatId, message }) {
    try {
      const response = await ChatService.sendMessage(chatId, message);
      if(response.chat) {
        commit('UPDATE_CHAT', { chat: response.chat });
      }
      commit('ADD_MESSAGE', { chatId, message: response.message, message_out: true });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  },
  async editMessage({ commit }, { messageId, message }) {
    try {
      const response = await ChatService.editMessage(messageId, message);
      if(response.chat) {
        commit('UPDATE_CHAT', { chat: response.chat });
      }
      commit('UPDATE_MESSAGE', { chatId: state.selectedChat, message: response.message });
    } catch (error) {
      console.error('Error editing message:', error);
    }
  },
  async deleteMessage({ commit }, messageId) {
    try {
      const response = await ChatService.deleteMessage(messageId);
      if(response.success) {
        commit('DELETE_MESSAGE', { chatId: state.selectedChat, messageId });
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  },
  // eslint-disable-next-line
  async readMessage({ commit }, messageId) {
    try {
      const chatId = state.selectedChat
      const response = await ChatService.readMessage(chatId, messageId);
      if(response.chat) {
        commit('UPDATE_CHAT', { chat: response.chat });
      }
    } catch (error) {
      console.error('Error reading message:', error);
    }
  },
  async join({ commit, dispatch }) {
    try {
      const chatId = state.selectedChat

      const response = await ChatService.join(chatId);
      commit('UPDATE_CHAT', { chat: response.chat });
      dispatch('getChatMembers', chatId);
    } catch (error) {
      console.error('Error while joining chat:', error);
    }
  },
  async leave({ commit, dispatch }) {
    try {
      const chatId = state.selectedChat

      const response = await ChatService.leave(chatId);
      commit('UPDATE_CHAT', { chat: response.chat });
      dispatch('getChatMembers', chatId);
    } catch (error) {
      console.error('Error while leaving chat:', error);
    }
  },
  async enterIndividual({ commit, dispatch }, contact_id) {
    try {
      // const chatId = state.selectedChat

      const response = await UserService.getUserInfo(contact_id);

      if(response.chat_id) {
        dispatch('selectChat', response.chat_id);
      } else {
        const chat = {
          chat_id: -1,
          name: null,
          is_group: 0,
          avatar: null,
          is_muted: 0,
          user: response
        }
        commit('UPDATE_CHAT', { chat });
        commit('SET_SELECTED_CHAT', chat.chat_id);

      }
      // dispatch('getChatMembers', chatId);
    } catch (error) {
      console.error('Error fetching chat members:', error);
    }
  },
  async startIndividual({ commit }, { contact_id, message }) {
    try {
      const response = await ChatService.startIndividual(contact_id, message);
      if(response.chat) {
        commit('UPDATE_EMPTY_CHAT', { chat: response.chat });
        commit('SET_SELECTED_CHAT', response.chat.chat_id );
      }
      commit('ADD_MESSAGE', { chatId: response.chat.chat_id, message: response.message });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  },
  setSearchQuery({ commit }, query) { // Add action for searchQuery
    commit('SET_SEARCH_QUERY', query);
  },
  initSocket({ commit, dispatch }) {
    ChatService.initSocket();
    dispatch('getList');
    dispatch('getContacts');
    ChatService.onChatsFetched((chats) => {
      commit('SET_CHATS', chats);
    });
    ChatService.onMessageReceived((res) => {
      if(res.chat) {
        commit('UPDATE_CHAT', { chat: res.chat });
      }
      commit('ADD_MESSAGE', { chatId: res.message.chat_id, message: res.message });
    });
    ChatService.onMessagesFetched((chatId, messages) => {
      commit('SET_MESSAGES', { chatId, messages });
    });
  },
  closeSocket(){
    ChatService.closeSocket();
  }
};

export const getters = {
  chatInfo: (state) => {
    return state.chats.find((chat) => chat.chat_id === state.selectedChat);
  },
  chatMessages: (state) => (chatId) => {
    return state.chatMessages[chatId] || [];
  },
  filteredChats: (state) => { // Add getter for filtered chats
    if (!state.searchQuery) {
      return state.chats;
    }
    return state.chats.filter(chat =>
      chat.name.toLowerCase().includes(state.searchQuery.toLowerCase())
    );
  },
};
