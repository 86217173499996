//service
import api from '@/api';

class PromptService {
  getAll() {
    return api.get('/data/prompts');
  }

  get(id) {
    return api.get(`/data/prompts/${id}`);
  }

  add(message) {
    return api.post('/data/prompts', { message });
  }

  edit(id, message) {
    return api.put(`/data/prompts/${id}`, { message });
  }

  delete(id) {
    return api.delete(`/data/prompts/${id}`);
  }
}

export default new PromptService();
