import api from '@/api';

class UserService {
  getUser() {
    // return 
    //localStorage.getItem('user') ??

    return api.get('/user-profile')      
        .then((response) => {
            const user = response.data
            localStorage.setItem('user', JSON.stringify(user))
            return user;
        });
  }

  getUserInfo(userId) {
    return api.get('/user-profile/' + userId)      
    .then((response) => {
        const user = response.data
        localStorage.setItem('user', JSON.stringify(user))
        return user;
    });
  }
}

export default new UserService();
