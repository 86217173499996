import CalendarService from '@/services/calendar.service';

 export const state = {
  calendars: []
 }

 export const mutations = {
  SET_CALENDARS(state, calendars) {
    state.calendars = calendars;
  },
  DELETE_CALENDAR(state, id) {
    state.calendars = state.calendars.filter(calendar => calendar.id !== id);
  },
  EDIT_CALENDAR(state, calendar) {
    const index = state.calendars.findIndex(m => m.id === calendar.id);
    if (index !== -1) {
      state.calendars[index] = { ...state.calendars[index], ...calendar };
    }
  },
  ADD_CALENDAR(state, calendar) {
    state.calendars.unshift(calendar);  // Add at the beginning
  }
};


export const actions = {
  async getAllCalendars({ commit }) {
    try {
      const response = await CalendarService.getAll();
      commit('SET_CALENDARS', response.calendars);
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  },
  
  async deleteCalendar({ commit }, id) {
    try {
      const response = await CalendarService.delete(id);
      if (response.status === 200 || response.status === 204) {
        commit('DELETE_CALENDAR', id);
      } else {
        console.error('Failed to delete calendar. Server returned:', response.status);
      }
    } catch (error) {
      console.error('Error deleting calendar:', error);
    }
  },
  
  async editCalendar({ commit }, calendar) {
    try {
      const response = await CalendarService.edit(calendar.id, calendar);
      if (response.status === 200) {
        commit('EDIT_CALENDAR', calendar);
      } else {
        console.error('Failed to edit calendar. Server returned:', response.status);
      }    
    } catch (error) {
      console.error('Error editing calendar:', error);
    }
  },
  
  async addCalendar({ commit }, calendar) {
    try {
      const response = await CalendarService.add(calendar);
      if (response.status === 201) {
        commit('ADD_CALENDAR', response.data.calendar);
      } else {
        console.error('Failed to add calendar. Server returned:', response.status);
      }    } catch (error) {
      console.error('Error adding calendar:', error);
    }
  }
};


export const getters = {
  calendars: state => state.calendars,
  calendarList: state => state.calendars
};

