import axios from 'axios';
import AuthService from '@/services/auth.service';
import TokenService from '@/services/token.service';
import router from '@/router';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


api.interceptors.request.use(
  async (config) => {
    const token = TokenService.get();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    } 
    //else {
    //  await AuthService.refreshToken();
    //  const newToken = TokenService.getToken();
    //  if (newToken) {
    //    config.headers['Authorization'] = 'Bearer ' + newToken;
    //  }
    //}
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/login-check" && originalConfig.url !== "/refresh_token" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await TokenService.refresh()
          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      AuthService.logout();
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default api;
