import ResourcesService from '@/services/resource.service';

 export const state = {
  resources: []
 }

 export const mutations = {
  SET_RESOURCES(state, resources) {
    state.resources = resources;
  },
  DELETE_RESOURCE(state, id) {
    state.resources = state.resources.filter(resource => resource.id !== id);
  },
  EDIT_RESOURCE(state, resource) {
    const index = state.resources.findIndex(m => m.id === resource.id);
    if (index !== -1) {
      state.resources[index] = { ...state.resources[index], ...resource };
    }
  },
  ADD_RESOURCE(state, resource) {
    state.resources.unshift(resource);  // Add at the beginning
  }
};


export const actions = {
  async getAllResources({ commit }) {
    try {
      const response = await ResourcesService.getAll();
      commit('SET_RESOURCES', response.resources);
    } catch (error) {
      console.error('Error fetching resources:', error);
    }
  },
  
  async deleteResource({ commit }, id) {
    try {
      const response = await ResourcesService.delete(id);
      if (response.status === 200 || response.status === 204) {
        commit('DELETE_RESOURCE', id);
      } else {
        console.error('Failed to delete resource. Server returned:', response.status);
      }
    } catch (error) {
      console.error('Error deleting resources:', error);
    }
  },
  
  async editResource({ commit }, resource) {
    try {
      const response = await ResourcesService.edit(resource.id, resource);
      if (response.status === 200) {
        commit('EDIT_RESOURCE', resource);
      } else {
        console.error('Failed to edit resource. Server returned:', response.status);
      }    
    } catch (error) {
      console.error('Error editing resources:', error);
    }
  },
  
  async addResource({ commit }, resource) {
    try {
      const response = await ResourcesService.add(resource);
      if (response.status === 201) {
        commit('ADD_RESOURCE', response.data.resource);
      } else {
        console.error('Failed to add resource. Server returned:', response.status);
      }    } catch (error) {
      console.error('Error adding resources:', error);
    }
  }
};


export const getters = {
  resources: state => state.resources,
  resourceList: state => state.resources
};

