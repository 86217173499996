import api from '@/api';
import TokenService from './token.service';

class AuthService {
  login(user) {
    return api
      .post('/login-check', {
        phoneNumber: user.phoneNumber,
        verificationCode: user.verificationCode,
      })
      .then((response) => {
        if (response.data.token) {
          const token = response.data.token
          TokenService.set(token)
        }
        if (response.data.refresh_token) {
          const refresh_token = response.data.refresh_token
          localStorage.setItem('refresh_token', refresh_token);
        }
        return response.data;
      });
  }

  logout() {
    TokenService.clear()
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  }

  // async refreshToken() {
  //   const refresh_token = localStorage.getItem('refresh_token');
  //   const response = await api.post("/refresh_token", {refresh_token})
  //   if (response.data.token) {
  //     const token = response.data.token
  //     TokenService.set(token)
  //     return token
  //   }  
  // }

  sendVerificationCode(user) {
    return api.post('/login-start', {         
        phoneNumber: user.phoneNumber,
    });
  }
}

export default new AuthService();
