import api from '@/api';

class CalendarService {
  getAll() {
    return api.get('/calendar/all')      
        .then((response) => {
            const user = response.data
            return user;
        });
  }

  get(id) {
    return api.get(`/calendar/${id}`);
  }

  edit(id, data) {
    return api.put(`/calendar/${id}`, data);
  }

  delete(id) {
    return api.delete(`/calendar/${id}`);
  }

  add(data) {
    return api.post(`/calendar/add`, data);
  }
}

export default new CalendarService();
