import AssistantService from '@/services/assistant.service';
import { marked } from 'marked';


export const state = {
  list: [],
  messages: {},
  chatMessages: {},
  selectedChat: null,
  searchQuery: '', // Add searchQuery state
};

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_CHAT_MESSAGES(state, { chatId, messages }) {
    state.chatMessages[chatId] = messages;
  },
  SET_MESSAGES(state, { chatId, messages }) {
    for(let message of messages) {
      if(message.is_reply) {
        message.marked = marked(message.message)
      }
    }
    console.log('messages: ', messages)
    state.chatMessages[chatId] = messages;
  },
  SET_SELECTED_CHAT(state, chatId) {
    state.selectedChat = chatId;
  },
  SET_SELECT_GENERAL(state) {
    const existingGeneral = state.list.findIndex(chat => chat.l_key === 'general');

    if( existingGeneral !== -1 ) {
      state.selectedChat = state.list[existingGeneral].id;
    }
  },
  ADD_MESSAGE(state, { chatId, message }) {
    if (!state.chatMessages[chatId]) {
      state.chatMessages[chatId] = [];
    }
    const existingGeneratingMessageIndex = state.chatMessages[chatId].findIndex(msg => msg.id == message.id);
  
    if (existingGeneratingMessageIndex !== -1) {
    // Update the existing generating message
      message.marked = marked(message.message)
      state.chatMessages[chatId][existingGeneratingMessageIndex] = message;
    } else {
      state.chatMessages[chatId] = [ ...state.chatMessages[chatId], message ]
    }
    // state.chatMessages[chatId].push(message);
  },
  CLEAR_MESSAGES(state) {
    state.chatMessages = {}
  },
  SET_SEARCH_QUERY(state, query) { // Add mutation for searchQuery
    state.searchQuery = query;
  },
};

export const actions = {
  async getList({ commit, dispatch }) {
    try {
      const list = await AssistantService.getList();
      commit('SET_LIST', list);
      commit('SET_SELECT_GENERAL')
      dispatch('getMessages', state.selectedChat);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  },
  async getMessages({ commit }, chatId) {
    try {
      const messages = await AssistantService.getMessages(chatId);
      commit('SET_MESSAGES', { chatId, messages });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  },
  async fetchChats() {
    // try {
    //   const response = await AssistantService.getChats();
    //   commit('SET_CHATS', response.data.chats);
    // } catch (error) {
    //   console.error('Error fetching chats:', error);
    // }
    AssistantService.fetchChats();
  },
  async fetchChatMessages({ commit }, chatId) {
    try {
      const response = await AssistantService.getChatMessages(chatId);
      commit('SET_CHAT_MESSAGES', { chatId, messages: response.data.messages });
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  },
  // eslint-disable-next-line
  fetchMessages({ state }, chatId) {
    AssistantService.fetchMessages(chatId);
  },
  selectChat({ commit, dispatch }, chatId) {
    commit('SET_SELECTED_CHAT', chatId);
    dispatch('getMessages', chatId);
  },
  async sendMessage({ commit }, { chatId, message }) {
    // AssistantService.sendMessage(chatId, message.message);
    try {
      const sentMessage = await AssistantService.sendMessage(chatId, message.message);
      commit('ADD_MESSAGE', { chatId: sentMessage.bot_id, message: sentMessage });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  },
  async clearThread({ dispatch }) {
    try {
      const response = await AssistantService.clearThread();
      if(response.success) {
        dispatch('getList');
      }
    } catch (error) {
      console.error('Error clearing thread:', error);
    }
  },
  async newThread({ dispatch }, { chatId, message }) {
    try {
      const response = await AssistantService.clearThread();
      if(response.success) {
        dispatch('getList');
        dispatch('sendMessage',{ chatId, message });
      }
    } catch (error) {
      console.error('Error clearing thread:', error);
    }
  },
  setSearchQuery({ commit }, query) { // Add action for searchQuery
    commit('SET_SEARCH_QUERY', query);
  },
  initSocket({ commit, dispatch }) {
    AssistantService.initSocket();
    dispatch('getList');
    // getList();
    // AssistantService.getList((list) => {
    //   console.log('sdfjhgsdfjhg1231')
    //   commit('SET_LIST', list);
    // });
    // AssistantService.onChatsFetched((chats) => {
    //   commit('SET_CHATS', chats);
    // });
    AssistantService.onMessageGenerating((message) => {
      console.log('stream: ', message)
      // commit('ADD_MESSAGE', { chatId: message.bot_id, message });
    });
    AssistantService.onMessageReceived((message) => {
      commit('ADD_MESSAGE', { chatId: message.bot_id, message });
    });
    AssistantService.onMessagesFetched((chatId, messages) => {
      commit('SET_MESSAGES', { chatId, messages });
    });
  },
  closeSocket(){
    AssistantService.closeSocket();
  }
};

export const getters = {
  selectedChat: (state) => {
    return state.chats.find((chat) => chat.id === state.selectedChat);
  },
  chatMessages: (state) => (chatId) => {
    return state.chatMessages[chatId] || [];
  },
  filteredChats: (state) => { // Add getter for filtered chats
    if (!state.searchQuery) {
      return state.chats;
    }
    return state.chats.filter(chat =>
      chat.name.toLowerCase().includes(state.searchQuery.toLowerCase())
    );
  },
};

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions,
//   getters,
// };
